.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* html {
  height: 100%;
}

body{
  min-height: 100%;
  position: relative;
} */

html,
body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

body > * {
  flex-shrink: 0;
}


body.modal-open {
  overflow: hidden;
}

#root {
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0px;
}

p {
  margin-top: 5px;
  margin-bottom: 5px;
}

@font-face {
  font-family: "SetoFont";
  src: url(https://cdn.jsdelivr.net/gh/max32002/naikaifont@1.89/webfont/NaikaiFont-Regular.woff2)
    format("woff2");
}
